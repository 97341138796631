import Model from "../Model";

class Edition extends Model {
    buildUrl ({ param }) {
        return ['editions', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'editions',
            data: form
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'editions/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'editions/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'editions/destroy',
            data: form
        });
    }

    async funnel(form) {
        return await this.request({
            method: 'POST',
            url: 'editions/funnel',
            data: form
        });
    }

    async customersSyncForced(form) {
        return await this.request({
            method: 'POST',
            url: 'editions/customers-sync-forced',
            data: form
        });
    }
}

export default new Edition;